import { render, staticRenderFns } from "./Newcoin.vue?vue&type=template&id=ffc46bdc&scoped=true&"
var script = {}
import style0 from "./Newcoin.vue?vue&type=style&index=0&id=ffc46bdc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffc46bdc",
  null
  
)

export default component.exports